<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @view="onView"
            responsive="sm"
            hover
            :serverSideSorting="true"
            stateModule="complain/list"
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { ComplainModel } from '@/modules/complain/complain-model';
    import { ComplainPermissions } from '@/modules/complain/complain-permissions';

    const { fields } = ComplainModel;

    export default {
        name: 'ComplainListTable',
        data() {
            return {
                presenter: ComplainModel.presenter,
                actions: [
                    {
                        name: 'view',
                        icon: 'eye',
                        tooltipText: 'Vizualizare',
                        customIcon: false,
                        type: 'primary'
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'complain/list/rows',
                currentUser: 'auth/currentUser',
            }),
            fields() {
                if (this.hasPermissionToStaffComplains) {
                    return this.staffTableFields;
                }
                return this.tableFields;
            },
            tableFields() {
                return [
                    fields.reporting_user_full_name.extendField({ sortable: true}),
                    fields.contact_user_full_name.extendField({ sortable: true}),
                    fields.identification_number,
                    fields.created_date.extendField({ sortable: true}),
                    fields.complain_type_name.extendField({ bold: true, sortable: true }),
                    fields.uat.extendField({ sortable: true}),
                    fields.locality_name.extendField({ sortable: true}),
                    fields.status.extendField({ labelType: true }),
                ]
            },
            staffTableFields() {
                return [
                    fields.assigned_user_name.extendField({ customField: true, portalTarget: 'enable-truncate'}),
                    fields.reporting_user_full_name.extendField({ sortable: true}),
                    fields.contact_user_full_name.extendField({ sortable: true}),
                    fields.identification_number,
                    fields.created_date.extendField({ bold: true, sortable: true }),
                    fields.uat.extendField({ sortable: true}),
                    fields.locality_name.extendField({ sortable: true}),
                    fields.complain_type_name.extendField({ bold: true, sortable: true }),
                    fields.status.extendField({ labelType: true }),
                ]
            },
            hasPermissionToStaffComplains() {
                return new ComplainPermissions(this.currentUser).readStaff;
            },
        },
        methods: {
            onView(data) {
                if (!data?.item?.id) return;
                this.$router.push({
                    name: 'complainView',
                    params: {
                        id: data.item.id,
                    },
                });
            },
        },
    };
</script>

<style scoped>

</style>
