<template>
    <st-page
        :title="$t('COMPLAIN.LIST.TITLE')"
    >
        <loader v-if="isLoading"/>
        <template #toolbar>
            <complain-list-toolbar/>
        </template>
        <complain-list-filter
            ref="complainListFilter"
            v-if="initLoading"
        >
            <complain-list-table/>
        </complain-list-filter>
        <select-organisation-modal
            ref="selectOrganisationModal"
            @organisationSelected="organisationSelected"
        ></select-organisation-modal>
    </st-page>
</template>

<script>
import ComplainListFilter from '../components/ComplainListFilter';
import ComplainListTable from '../components/ComplainListTable';
import ComplainListToolbar from '../components/ComplainListToolbar';
import SelectOrganisationModal from '@/modules/administrator/components/modals/SelectOrganisationModal.vue';
import Roles from '@/security/roles';
import { mapGetters } from "vuex";

export default {
    name: 'ComplainList',
    components: {
        ComplainListFilter,
        ComplainListTable,
        ComplainListToolbar,
        SelectOrganisationModal
    },
    data() {
        return {
            initLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
        }),
        loadingFetch() {
            return this.loading['complain/get'];
        },
        isLoading() {
            return this.loadingFetch;
        },
        isSuperAdmin() {
            return this.currentUser.user_type_name === Roles.values['super_admin']
        }
    },
    mounted() {
        if (this.isSuperAdmin) {
            this.initLoading = false;
            this.$refs.selectOrganisationModal.show();
        } else {
            this.initLoading = true;
        }
    },
    methods: {
        organisationSelected() {
            this.initLoading = true;
            this.$refs.selectOrganisationModal.hide();
        },
    }
}
</script>
